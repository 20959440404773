<template>
  <div class="container">
    <div class="top">
      <div class="header">
        <a href="/">
          <img
            src="~@/assets/logo.png"
            class="logo"
            alt="logo"
          >
          <span class="title">沈阳先锋管理云平台</span>
        </a>
      </div>
      <div class="desc">
        <div class="main">
          <a-form-model
            id="formLogin"
            ref="form"
            class="user-layout-login"
            :model="form"
            :rules="rules"
          >
            <a-alert
              v-if="isLoginError"
              type="error"
              showIcon
              style="margin-bottom: 24px;"
              :message="loginErrorInfo"
              closable
              :after-close="handleCloseLoginError"
            />
            <a-form-model-item prop="accountNo">
              <a-input
                v-model="form.accountNo"
                size="large"
                placeholder="账户: 请输入账号"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input-password
                v-model="form.password"
                size="large"
                placeholder="密码: 请输入密码"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input-password>
            </a-form-model-item>
            <a-row
              :gutter="16"
              v-if="captchaEnabled"
            >
              <a-col
                class="gutter-row"
                :span="16"
              >
                <a-form-model-item prop="code">
                  <a-input
                    v-model="form.code"
                    size="large"
                    type="text"
                    autocomplete="off"
                    placeholder="验证码"
                  >
                    <a-icon
                      slot="prefix"
                      type="security-scan"
                      :style="{ color: 'rgba(0,0,0,.25)' }"
                    />
                  </a-input>
                </a-form-model-item>
              </a-col>
              <a-col
                class="gutter-row"
                :span="8"
              >
                <img
                  class="getCaptcha"
                  :src="codeUrl"
                  @click="getCode"
                >
              </a-col>
            </a-row>
            <!-- <a-form-model-item prop="rememberMe">
              <a-checkbox
                :checked="form.rememberMe"
                @change="rememberMe"
              >记住密码</a-checkbox>
            </a-form-model-item> -->
            <a-form-item style="margin-top:24px">
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="login-button"
                :loading="logining"
                :disabled="logining"
                @click="handleSubmit"
              >确定</a-button>
            </a-form-item>
            <div class="user-login-other">
              <!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->

            </div>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'

export default {
  components: {
  },
  data () {
    return {
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        accountNo: '',
        password: '',
        code: undefined,
        uuid: '',
        rememberMe: false
      },
      rules: {
        accountNo: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created () {
    this.getStorage()
    if (this.captchaEnabled) {
      this.getCode()
    }
  },
  methods: {
    getCode () {
      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled
        if (this.captchaEnabled) {
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.form.uuid = res.uuid
        }
      })
    },
    getStorage () {
      const accountNo = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (accountNo) {
        this.form = {
          accountNo: accountNo,
          password: password,
          rememberMe: rememberMe
        }
      }
    },
    rememberMe (e) {
      this.form.rememberMe = e.target.checked
    },
    ...mapActions(['Login', 'Logout']),
    handleSubmit () {
      const that = this
      this.logining = true
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.rememberMe) {
            storage.set(LOGIN_USERNAME, this.form.accountNo)
            storage.set(LOGIN_PASSWORD, this.form.password)
            storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
          } else {
            storage.remove(LOGIN_USERNAME)
            storage.remove(LOGIN_PASSWORD)
            storage.remove(LOGIN_REMEMBERME)
          }
          this.$store.dispatch('Login', this.form).then((res) => {
            console.log('999', res)
            this.$router.push({ path: 'home' }).catch(() => {
              // that.$store.commit('IS_HOME', true)
            })
          }).catch((err) => {
            console.log('err', err)
            this.loading = false
            setTimeout(() => {
              this.logining = false
            }, 1000)
            if (this.captchaEnabled) {
              this.getCode()
            }
          })
          // this.Login(this.form)
          //   .then((res) => this.loginSuccess(res))
          //   .catch(err => this.requestFailed(err))
          //   .finally(() => {
          //     this.logining = false
          //   })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess (res) {
      // this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed (err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError () {
      this.isLoginError = false
      this.loginErrorInfo = ''
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .register {
      float: right;
    }
  }
}

#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    // background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background: url(~@/assets/bei.jpg) no-repeat;

    background-size: 100% 100%;
    padding: 110px 0 144px;
    position: relative;

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          top: 2px;
        }
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {
      min-width: 260px;
      width: 368px;
      margin: 0 auto;
      margin-top: 120px;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;

        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;

          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }

      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  }
}

/deep/.ant-form-explain {
  text-align: left !important;
}
</style>
